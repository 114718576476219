import { UserProfile } from '@auth0/nextjs-auth0/client';
import Image from 'next/image';
import { Roles, roles, useProfileStore } from '@/stores';
import { Button } from '@/components/Button/Button';
import { CgArrowsExchange } from 'react-icons/cg';
import {
    DialogDismissButton,
    handleDismissModal,
    useDialog,
    useDialogHandler,
} from '@/components/Dialog/DialogHandler';
import { useState } from 'react';

export function SeeAsRoleDialog() {
    const { state, setState } = useDialogHandler();
    const [selectedRole, setSelectedRole] = useState<Roles>();
    const { testRole, setTestRole } = useProfileStore();

    const handleConfirmDialog = (role?: Roles) => {
        if (role) {
            setTestRole(role);
        }
        handleDismissModal(state, setState);
    };

    const handleDeleteTestRole = () => {
        setTestRole(undefined);
        setSelectedRole(undefined);
        handleDismissModal(state, setState);
    };

    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col rounded-lg bg-white  opacity-100">
                <div className="flex items-center p-6">
                    <h2 className="flex-1 text-lg font-semibold">Ver connect como</h2>
                    <DialogDismissButton />
                </div>
                <div className="min-w-[420px] space-y-5 px-6">
                    <ul className="space-y-3">
                        {Object.entries(roles)
                            .slice(1)
                            .map(([key, value]) => (
                                <li key={key}>
                                    <label className="flex items-center gap-2 text-sm" htmlFor={key}>
                                        <input
                                            type="radio"
                                            defaultChecked={testRole === key}
                                            name="role"
                                            id={key}
                                            onChange={() => setSelectedRole(key as keyof typeof roles)}
                                        />
                                        {value}{' '}
                                        {testRole === key && <span className="text-xs text-gray-500">(Actual)</span>}
                                    </label>
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="flex justify-end gap-4 p-6">
                    {testRole && (
                        <Button size="xs" color="red" onClick={handleDeleteTestRole}>
                            Eliminar
                        </Button>
                    )}

                    <Button
                        size="xs"
                        disabled={!selectedRole}
                        color="blue"
                        onClick={() => handleConfirmDialog(selectedRole)}
                    >
                        Confirmar
                    </Button>
                </div>
            </div>
        </div>
    );
}

export function ConfigView({ user }: { user: UserProfile }) {
    const dialog = useDialog();
    const { isAdmin, testRole, getProfileRoles } = useProfileStore();

    const handleOpenSeeAsRoleDialog = () => {
        dialog({
            type: 'modal',
            content: <SeeAsRoleDialog />,
        });
    };

    return (
        <section>
            <h1 className="font-semibold text-2xl pb-6">Configuración</h1>
            <div className="flex max-w-3xl flex-col">
                <div className="flex flex-col gap-3 rounded-t-lg bg-gray-100 px-5 py-2">
                    <span className="text-gray-900">Datos personales</span>
                </div>
                <div className="flex gap-5 bg-white p-5 rounded-b-lg">
                    <div className="min-w-[70px] min-h-[70px]">
                        <Image
                            src={user?.picture ?? '/images/avatar.png'}
                            className="rounded-full"
                            width={80}
                            height={80}
                            alt="User avatar"
                            priority={true}
                        />
                    </div>

                    <div className="w-full flex flex-col justify-between">
                        <div className="flex justify-between">
                            <div className="flex flex-col">
                                <span className="text-lg font-medium text-gray-800">{user?.name}</span>
                                <span className="text-sm text-gray-500">{user?.email}</span>
                            </div>
                            {isAdmin(true) && (
                                <Button
                                    color="blue"
                                    variant="outline"
                                    size="small"
                                    className="text-lg h-fit"
                                    onClick={handleOpenSeeAsRoleDialog}
                                >
                                    <CgArrowsExchange className="h-5 w-5" />
                                    Probar Rol
                                </Button>
                            )}
                        </div>

                        <div className="mt-2">
                            {testRole ? (
                                <span
                                    key={testRole}
                                    className="text-blue-500 font-semibold border border-blue-500 py-1 px-2 rounded-lg mr-2"
                                >
                                    {roles[testRole as Roles].toUpperCase()}
                                </span>
                            ) : (
                                getProfileRoles().map((role) => (
                                    <span
                                        key={role}
                                        className="text-white font-semibold bg-blue-500 py-1 px-2 rounded-lg mr-2"
                                    >
                                        {roles[role].toUpperCase()}
                                    </span>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
